<template>
    <header :class="{ 'd-none d-md-block': !showOnMobile, 'border-bottom-0': showClose }">
        <div class="container">
            <div class="left">
                <a
                    v-if="showBack"
                    class="back d-block"
                    @click="handleClose"
                    data-testid="page-header-back"
                >
                    <img
                        src="@/assets/left-arrow.svg"
                        :alt="$t('accessibility.back')"
                    >
                </a>
                <a
                    v-if="showClose"
                    class="back d-block"
                    @click="handleClose"
                    data-testid="page-header-close"
                >
                    <img
                        src="@/assets/images/global/close.svg"
                        :alt="$t('accessibility.close')"
                    >
                </a>
            </div>
            <div class="center d-inline-block text-truncate px-4">
                <h5 class="mb-0">
                    {{ title }}
                </h5>
            </div>
            <div class="right">
                <slot name="right" />
            </div>
        </div>

        <div
            v-if="errorText"
            class="text-center mx-3 px-4 alert alert-warning mt-1"
            role="alert"
        >
            <span v-html="errorText" />
        </div>
    </header>
</template>

<script>
    export default {
        name: 'PageHeader',
        props: {
            title: {
                type: String,
                required: false,
            },
            showBack: {
                type: Boolean,
                default: false,
            },
            showClose: {
                type: Boolean,
                default: false,
            },
            showOnMobile: {
                type: Boolean,
                default: true,
            },
            errorText: {
                type: String,
                default: '',
            },
        },
        methods: {
            handleClose() {
                this.$emit('onClose')
            },
            handleDelete() {
                this.$emit('onDelete')
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '../styles/components/pageHeader';
</style>

<template>
    <div class="mb-6 mt-5">
        <div class="field d-flex justify-content-between">
            <div>
                {{ $t('components.balanceTransferOrCashOutInfo.status') }}
            </div>
            <div class="text-end text-muted">
                <span
                    class="status-indicator"
                    :class="status"
                />
                {{ $t('components.balanceTransferOrCashOutInfo.' + status) }}
            </div>
        </div>

        <div class="field d-flex justify-content-between">
            <div>
                {{ $t('components.balanceTransferOrCashOutInfo.from') }}
            </div>
            <div class="text-end text-muted">
                {{ balanceTransferOrCashOutData.lender }}
            </div>
        </div>
        <div class="field d-flex justify-content-between">
            <div>
                {{ $t('components.balanceTransferOrCashOutInfo.dateInitiated') }}
            </div>
            <div class="text-end text-muted">
                {{ formatDate(balanceTransferOrCashOutData.initiationDate) }}
            </div>
        </div>
        <div class="field d-flex justify-content-between">
            <div>
                {{ $t('components.balanceTransferOrCashOutInfo.amount') }}
            </div>
            <div class="text-end text-muted">
                {{ toFormattedUSD(balanceTransferOrCashOutData.amount) }}
            </div>
        </div>

        <div class="field d-flex justify-content-between">
            <div>
                {{ $t('components.balanceTransferOrCashOutInfo.fee') }}
            </div>
            <div class="text-end text-muted">
                {{ toFormattedUSD(balanceTransferOrCashOutData.btFee) }}
            </div>
        </div>
        <div v-if="hasLoanTerms">
            <section-header>{{ $t('components.balanceTransferOrCashOutInfo.paybackDetails') }}</section-header>
            <div class="field d-flex justify-content-between">
                <div>
                    {{ $t('components.balanceTransferOrCashOutInfo.method') }}
                </div>
                <div class="text-end text-muted">
                    {{ $t('components.balanceTransferOrCashOutInfo.fixed') }}
                </div>
            </div>
            <div class="field d-flex justify-content-between">
                <div>
                    {{ $t('components.balanceTransferOrCashOutInfo.termLength') }}
                </div>
                <div class="text-end text-end text-muted">
                    {{ balanceTransferOrCashOutData.loanTermInYears }} Years
                </div>
            </div>
            <div
                v-if="!!balanceTransferOrCashOutData.loanBalanceRemaining"
                class="field d-flex justify-content-between"
            >
                <div>
                    {{ $t('components.balanceTransferOrCashOutInfo.totalRemaining') }}
                </div>
                <div class="text-end text-muted">
                    {{ toFormattedUSD(balanceTransferOrCashOutData.loanBalanceRemaining) }}
                </div>
            </div>
            <section-header>{{ $t('components.balanceTransferOrCashOutInfo.monthlyBreakdown') }}</section-header>
            <div class="field d-flex justify-content-between">
                <div>
                    {{ $t('components.balanceTransferOrCashOutInfo.principal') }}
                </div>
                <div class="text-end text-muted">
                    {{ toFormattedUSD(balanceTransferOrCashOutData.fixedPrincipalPerMonth) }}
                </div>
            </div>
            <div class="field d-flex justify-content-between">
                <div>
                    {{ $t('components.balanceTransferOrCashOutInfo.fixedFinanceCharge') }}
                </div>
                <div class="text-end text-muted">
                    {{ toFormattedUSD(balanceTransferOrCashOutData.fixedFinanceChargesPerMonth) }}
                </div>
            </div>
            <div class="field d-flex justify-content-between">
                <div>
                    {{ $t('components.balanceTransferOrCashOutInfo.totalMonthlyPayment') }}
                </div>
                <div class="text-end text-muted">
                    {{ toFormattedUSD(balanceTransferOrCashOutData.totalMonthlyPayment) }}
                </div>
            </div>
        </div>
        <div v-else>
            <section-header>{{ $t('components.balanceTransferOrCashOutInfo.paybackDetails') }}</section-header>
            <div class="field d-flex justify-content-between">
                <div>
                    {{ $t('components.balanceTransferOrCashOutInfo.method') }}
                </div>
                <div class="text-end text-muted">
                    {{ $t('components.balanceTransferOrCashOutInfo.revolving') }}
                </div>
            </div>
            <div class="field d-flex justify-content-between">
                <div>
                    {{ $t('components.balanceTransferOrCashOutInfo.apr') }}
                </div>
                <div class="text-end text-muted">
                    {{ balanceTransferOrCashOutData.apr }}%
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import format from '@/mixins/format'
    import SectionHeader from '@/components/SectionHeader'
    import generic from '@/utils/generic'

    export default {
        name: 'BalanceTransferOrCashOutInfo',
        components: {
            SectionHeader,
        },
        mixins: [format],
        props: {
            status: {
                type: String,
                required: true,
            },
            balanceTransferOrCashOutData: {
                type: Object,
                required: true,
            },
        },
        computed: {
            hasLoanTerms() {
                return this.balanceTransferOrCashOutData?.loanTerms > 0
            },
        },
        methods: {
            formatDate(date) {
                return generic.convertDateTimeFormat(date, { outputFormat: 'MMM DD YYYY' })
            },
        },
    }
</script>

<style lang="scss" scoped>
    .status-indicator {
        width: 0.75rem;
        height: 0.75rem;
        display: inline-block;
        margin-right: 0.5rem;
        border-radius: 100%;

        &.pending {
            background-color: $orange;
        }
        &.completed,
        &.reversal {
            background-color: #4a9f6e;
        }
        &.canceled {
            background-color: $red;
        }
    }

    .field:not(:last-child) {
        margin-bottom: 22px;
    }
</style>

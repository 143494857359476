<template>
    <p class="section-header text-muted mt-4 mb-2 text-center">
        <slot />
    </p>
</template>

<script>
    export default {
        name: 'SectionHeader',
    }
</script>

import store from '@/store'

export const navigation = {
    methods: {
        returnToRoot: async function () {
            console.log(`return to root - ${store.getters.currentRootPath}`)
            await this.$router.replace(store.getters.currentRootPath)
        },
        isFromDeeplink: function () {
            return this.$route.query.fromDeeplink === 'true'
        },
    },
}

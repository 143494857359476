<template>
    <div>
        <div
            v-if="loading"
            class="container"
        >
            <loading-indicator
                v-if="loading"
                :title="$t('global.loadingMessage.loading')"
            />
        </div>
        <div v-else>
            <page-header
                :title="title"
                :show-back="true"
                @onClose="$router.go(-1)"
            />
            <div
                class="container"
                v-if="errorText"
            >
                <div
                    class="alert alert-warning text-start mt-2"
                    role="alert"
                >
                    <span v-html="errorText" />
                </div>
            </div>

            <div
                class="container"
                v-if="isCancelationSuccess"
            >
                <div
                    class="alert alert-success text-start mt-2"
                    role="alert"
                >
                    {{ $t('pages.cashOutById.confirmationMessage') }}
                </div>
            </div>

            <div v-if="cashOutData">
                <div class="container">
                    <balance-transfer-or-cash-out-info
                        :balance-transfer-or-cash-out-data="cashOutData"
                        :status="status"
                    />
                    <base-button
                        v-if="isCancelable"
                        :submitting="isCanceling"
                        :disabled="isCancelationSuccess"
                        button-classes="btn btn-secondary"
                        @click="requestCancelation"
                    >
                        {{ $t('pages.cashOutById.cancelTransactionCta') }}
                    </base-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import LoadingIndicator from '@/components/LoadingIndicator'
    import PageHeader from '@/components/PageHeader'
    import { getCashOutById, requestCashOutCancelation } from '@/services/api'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import BaseButton from '@/components/base/BaseButton'
    import { i18n } from '@/utils/i18n'
    import { AciPaymentTransactionType, BalanceTransferStatus } from '@/store'
    import format from '@/mixins/format'
    import { delay } from '@/utils/async'
    import { navigation } from '@/mixins/navigation'
    import { DisplayableBalanceTransferOrCashOutStatus } from '@/data/constants'
    import BalanceTransferOrCashOutInfo from '@/components/BalanceTransferOrCashOutInfo'

    export default {
        name: 'CashOutById',
        components: {
            'balance-transfer-or-cash-out-info': BalanceTransferOrCashOutInfo,
            'loading-indicator': LoadingIndicator,
            'page-header': PageHeader,
            'base-button': BaseButton,
        },
        mixins: [format, navigation],
        data() {
            return {
                loading: true,
                errorText: null,
                cashOutData: null,
                isCanceling: false,
                isCancelationSuccess: false,
            }
        },
        async mounted() {
            try {
                const response = await getCashOutById(this.$route.params.id)
                this.cashOutData = response.data.payload
            } catch (error) {
                this.errorText = i18n.t('global.errors.generic')
                ApiErrorHandler(error)
            } finally {
                this.loading = false
            }
        },
        computed: {
            title() {
                return `CashOut To ${this.cashOutData?.lender}`
            },
            wasSentSuccessfullyToACIAndCoreCard() {
                // set of balance transfer state where the transaction should already be sent to ACI
                return [BalanceTransferStatus.Completed, BalanceTransferStatus.CoreCardSubmissionSuccess].includes(this.cashOutData.status)
            },
            isReversal() {
                return this.cashOutData?.transactionType === AciPaymentTransactionType.REVERSAL
            },
            isCanceled() {
                const canceledBeforeAciSubmission = this.cashOutData.status === BalanceTransferStatus.Canceled
                const canceledAfterAciSubmission = this.cashOutData.hasReversal
                return canceledBeforeAciSubmission || canceledAfterAciSubmission
            },
            status() {
                if (this.isReversal) {
                    return DisplayableBalanceTransferOrCashOutStatus.Reversal
                }
                if (this.isCanceled || this.isCancelationSuccess) {
                    return DisplayableBalanceTransferOrCashOutStatus.Canceled
                }
                if (this.wasSentSuccessfullyToACIAndCoreCard) {
                    return DisplayableBalanceTransferOrCashOutStatus.Completed
                }
                return DisplayableBalanceTransferOrCashOutStatus.Pending
            },
            isCancelable() {
                return (!this.wasSentSuccessfullyToACIAndCoreCard || this.cashOutData.aciPaymentStatus === 'Pending') && !this.isCanceled
            },
        },
        methods: {
            async requestCancelation() {
                try {
                    this.isCanceling = true
                    // show at least 1s of loading state
                    const [response] = await Promise.all([await requestCashOutCancelation(Number(this.$route.params.id)), delay(1000)])
                    if (response.data.success) {
                        this.isCancelationSuccess = true
                        this.$logEvent('event_cash_out_cancelation_request', { balanceTransferId: this.cashOutData.id })
                    } else {
                        this.errorText = i18n.t('global.errors.generic')
                    }
                } catch (error) {
                    this.errorText = i18n.t('global.errors.generic')
                    ApiErrorHandler(error)
                } finally {
                    this.isCanceling = false
                }
            },
        },
    }
</script>
